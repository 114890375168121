import { Injectable } from '@angular/core';
import { DsToastService } from '@bmw-ds/components';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(private toastService: DsToastService) { }

  /**
   * Show an error message using the DsToastService
   * @param error The error to be displayed
   */
  public showError(error: unknown) {
    let message;
    if (error instanceof Error) {
      message = error.message;
    } else if (error instanceof Object) {
      message = JSON.stringify(error);
    } else if (typeof error === 'string') {
      message = error;
    } else {
      message = 'Could not find appropriate way to show message';
    }

    this.toastService.pushToast({
      id: 'error',
      tone: 'critical',
      toastTitle: 'Something went wrong',
      toastText: message
    });
  }
}
