/**
 * Angular Framework
 */
import { NgModule, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

/**
 * Density framework
 */
import { DsNavigationBarModule } from '@bmw-ds/components';
import { DsHeaderModule } from '@bmw-ds/components';
import { DsImprintModule } from '@bmw-ds/components';
import { DsBoxModule } from '@bmw-ds/components';
import { DsButtonModule } from '@bmw-ds/components';
import { DsFormsModule } from '@bmw-ds/components';
import { DsFormFieldModule } from '@bmw-ds/components';
import { DsSegmentedControlModule } from '@bmw-ds/components';
import { DsMenuModule } from '@bmw-ds/components';
import { DsModalModule } from '@bmw-ds/components';
import { DsIconModule } from '@bmw-ds/components';
import { DsToastModule } from '@bmw-ds/components';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-community';

/**
 * App Components
 */
import { DispatcherOverviewComponent } from './dispatcher/dispatcher-overview/dispatcher-overview.component';
import { WheelsetGridComponent } from './shared/wheelset-grid/wheelset-grid.component';
import { HomeComponent } from './home/home.component';
import { WorkhouseoperativeComponent } from './workhouseoperative/workhouseoperative.component';
import { ButtonCellRendererComponent } from './shared/wheelset-grid/button-cell-renderer/button-cell-renderer.component';
import { ModalComponent } from './shared/modal/modal.component';
import { DispatcherConsignmentResponsiveComponent } from './dispatcher/dispatcher-consignment-responsive/dispatcher-consignment-responsive.component';
import { ResponsiveGridCellRendererComponent } from './dispatcher/dispatcher-consignment-responsive/responsive-grid-cell-renderer/responsive-grid-cell-renderer.component';
import { WorkhouseoperativeResponsiveComponent } from './workhouseoperative/workhouseoperative-responsive/workhouseoperative-responsive.component';
import { ResponsiveButtonCellRendererComponent } from './shared/wheelset-grid/responsive-button-cell-renderer/responsive-button-cell-renderer.component';

/**
 * Third Party
 */
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoginComponent } from './auth/login/login.component';
import { WorkhouseoperativeService } from './shared/workhouseoperative/workhouseoperative.service';
import { environment } from 'src/environments/environment';
import { ConsignmentService } from './shared/dispatcher/consignments/consignment.service';
import { WheelsetService } from './shared/dispatcher/wheelsets/wheelset.service';
import { AuthInterceptor } from './auth/auth.interceptor';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    DispatcherOverviewComponent,
    WheelsetGridComponent,
    HomeComponent,
    WorkhouseoperativeComponent,
    ButtonCellRendererComponent,
    ModalComponent,
    DispatcherConsignmentResponsiveComponent,
    ResponsiveGridCellRendererComponent,
    LoginComponent,
    WorkhouseoperativeResponsiveComponent,
    ResponsiveButtonCellRendererComponent,

  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DsNavigationBarModule,
    DsHeaderModule,
    DsModalModule,
    DsImprintModule.forRoot({
      phone: '5555555',
      electronicContact: 'change-me@bmwgroup.com'
    }),
    DsBoxModule,
    DsButtonModule,
    DsFormsModule,
    DsFormFieldModule,
    DsSegmentedControlModule,
    DsMenuModule,
    AgGridModule,
    DsIconModule,
    DsToastModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: WorkhouseoperativeService, useClass: environment.services.workhouseoperativeService },
    { provide: ConsignmentService, useClass: environment.services.consignmentService},
    { provide: WheelsetService, useClass: environment.services.wheelsetService},
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
