<ds-header
[navBarRef]="navBar" 
appName="{{ title }}"
[environmentTag]="environmentTagConfig"
[(userInfo)]="userInfo"
[(localeID)]="localeID"
[localeOptions]="localeOptions"
(localeIDChange)="changeLocale($event)"
[menuTemplate]="loginOptionTemplate">
</ds-header>
<div class="d-flex">  
  <div class="nav-bar-wrapper position-sticky">
    <ds-navigation-bar class="nav-bar-wrapper position-sticky" #navBar [items]="navigationItems"></ds-navigation-bar>
  </div>
  <div class="container-fluid py-6x">
    <router-outlet></router-outlet>
  </div>
</div>
<ng-template #loginOptionTemplate>
  <ds-list-item icon="login" [isSelectable]="true" routerLink="/login" *ngIf="!authService.isAuthenticated">Login</ds-list-item>
  <ds-list-item icon="logout" [isSelectable]="true" (isSelectedChange)="logout()" *ngIf="authService.isAuthenticated">Logout</ds-list-item>
</ng-template>
