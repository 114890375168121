<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * The following content * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and should be replaced. * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<h1 class="title-lg mt-10x mb-4x">Hey there</h1>
    <p class="text-paragraph mb-4x">
      Congratulations! Your app with Density is up and running. Let's build something amazing together!<br />
      Everything you need to know you'll find in Density's documentation.
    </p>
    <a ds-button href="https://density.bmwgroup.net" target="_blank" class="mb-15x">View Documentation</a>
    <div class="row">
      <div class="col-sm-12 col-lg-4 mb-6x">
        <ds-box [detachFooter]="true" class="h-100">
          <ds-box-header variant="slim">Do you need help?</ds-box-header>
          <ds-box-content>Become part of the Density Community! The MS Teams channel is generally your best bet for a quick answer.</ds-box-content>
          <ds-box-footer class="d-flex">
            <a
              ds-button
              icon="teams"
              variant="ghost"
              href="https://teams.microsoft.com/l/team/19%3a3ae38bd4debc4287889fcfc006c04323%40thread.skype/conversations?groupId=30b20eef-ffc7-4fa5-aed5-d2f15cfc324c&tenantId=ce849bab-cc1c-465b-b62e-18f07c9ac198"
              target="_blank"
            >
              Join Community
            </a></ds-box-footer>
        </ds-box>
      </div>
      <div class="col-sm-12 col-lg-4 mb-6x">
        <ds-box [detachFooter]="true" class="h-100">
          <ds-box-header variant="slim">Are you missing something?</ds-box-header>
          <ds-box-content>If you have a feature request or you found a bug, please duplicate the linked items in our Jira backlog and follow the instructions given there.</ds-box-content>
          <ds-box-footer class="d-flex">
            <a
              ds-button
              icon="bug"
              variant="ghost"
              href="https://atc.bmwgroup.net/jira/browse/DESY-2056"
              target="_blank"
              class="me-4x">
              Report bug
            </a>
            <a
              ds-button
              icon="jira"
              variant="ghost"
              href="https://atc.bmwgroup.net/jira/browse/DESY-2055"
              target="_blank">
              Request feature
            </a>
          </ds-box-footer>
        </ds-box>
      </div>
      <div class="col-sm-12 col-lg-4 mb-6x">
        <ds-box [detachFooter]="true" class="h-100">
          <ds-box-header variant="slim">Want to get in touch with Density Team?</ds-box-header>
          <ds-box-content>
            Use our email address <a href="mailto:uxdesignsystem@bmw.de" target="_blank">uxdesignsystem@bmw.de</a>
            whenever you have something you don't want or can't share with all the community.
          </ds-box-content>
          <ds-box-footer class="d-flex">
            <a ds-button icon="mail" variant="ghost" href="mailto:uxdesignsystem@bmw.de" target="_blank"> Email us </a>
          </ds-box-footer>
        </ds-box>
      </div>
    </div>
    <!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
