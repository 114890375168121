import { EventEmitter, Injectable } from '@angular/core';
import AuthenticatedUser from './auth.user.model';
import { HttpClient } from '@angular/common/http';

/**
 * This class provides an authentication service.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isAuthenticated = false;
  connectedUser: AuthenticatedUser = {
    Vorname: '',
    Nachname: '',
    NiederlassungId: 123
  }

  redirectUrl: string | null | undefined = null;

  userLoggedIn: EventEmitter<any> = new EventEmitter();

   /**
   * Constructor for the AuthService class.
   * @param httpClient - An instance of the HttpClient class.
   */
  constructor(private httpClient: HttpClient) {}

  /**
   * Method to log in a user.
   * @param username - The username of the user.
   * @param password - The password of the user.
   * @returns A Promise that resolves to a boolean indicating if the login was successful.
   */
  async login(username: string, password: string): Promise<boolean> {
    this.isAuthenticated = true;
    this.connectedUser = await this.logUserIn(username, password);
    this.userLoggedIn.emit(this.connectedUser);

    return true;
  }

  /**
   * Method to log out a user.
   * @returns A Promise that resolves to a boolean indicating if the logout was successful.
   */
  async logout(): Promise<boolean> {
    this.isAuthenticated = false;

    return true;
  }

  /**
   * Private method to log in a user.
   * @param username - The username of the user.
   * @param password - The password of the user.
   * @returns A Promise that resolves to the authenticated user.
   */
  private async logUserIn(username: string, password: string) {
    const returnedUser: AuthenticatedUser = {
      Nachname: 'Mustermann',
      Vorname: username,
      NiederlassungId: 123
    };

    return returnedUser;
  }
}
