import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import Consignment from 'src/app/shared/dispatcher/consignments/consignment.model';

/**
 * This class implements the ICellRendererAngularComp interface from the ag-grid-angular library.
 * It provides a custom cell renderer for an ag-grid.
 */
@Component({
  selector: 'spp-responsive-grid-cell-renderer',
  templateUrl: './responsive-grid-cell-renderer.component.html',
  styleUrls: ['./responsive-grid-cell-renderer.component.scss']
})
export class ResponsiveGridCellRendererComponent implements ICellRendererAngularComp {

  public params: any;
  public consignmentOrWheelset?: Consignment;

  /**
   * Method called by the ag-grid when the cell renderer is created.
   * @param params - The cell renderer parameters.
   */
  agInit(params: ICellRendererParams<any, any, any>): void {
    this.params = params;
    this.consignmentOrWheelset = this.params.data;
  }

  /**
   * Method called by the ag-grid when the cell renderer needs to be refreshed.
   * @param params - The cell renderer parameters.
   * @returns A boolean indicating if the cell renderer should be refreshed.
   */
  refresh(params: ICellRendererParams<any, any, any>): boolean {
    this.params = params;
    return true;
  }

  /**
   * Method to handle button clicks in the cell renderer.
   */
  public handleBtnClick() {
    this.params.clicked(this.params.data, this.params.showEditButton);
  }
}
