
import { Component, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';


@Component({
  selector: 'spp-button-cell-renderer', // Defines the custom HTML tag to use this component.
  templateUrl: './button-cell-renderer.component.html', // Path to the HTML template for the component.
  styleUrls: ['./button-cell-renderer.component.scss'] // Path to the styles for the component.
})
// The component class implements ICellRendererAngularComp, allowing it to be used as a cell renderer in AG Grid.
export class ButtonCellRendererComponent implements ICellRendererAngularComp {
  public isDynamic = false; // Indicates if the button behavior should be dynamic based on data.
  public params: any; // Holds the cell renderer parameters passed by AG Grid.
  public buttonTitle: string | undefined; // Stores the title for the button, which may change based on cell data.

  // AG Grid calls this method whenever it needs to refresh the cell. It returns true to indicate the cell has been refreshed.
  refresh(params: ICellRendererParams): boolean {
    this.params = params; // Update the parameters with the new values provided by AG Grid.
    return true; // Indicates the component successfully refreshed.
  }

  // AG Grid's initialization method for cell renderers. Called once when the cell is created.
  agInit(params: ICellRendererParams): void {
    this.params = params; // Store the parameters for later use.
    // Set the button title based on the status in the data.
    // 'Annehmen' for 'offen', 'Bearbeiten' for 'beendet', and 'Zuweisen' otherwise.
    this.buttonTitle = this.params.data.status === 'offen' ? 'Annehmen' : this.params.data.status === 'beendet' ? "Bearbeiten" : "Zuweisen";
    this.isDynamic = this.params.isDynamic; // Determine if the button's behavior is dynamic based on the parameters.
  }

  // Handler for button click events. Logs the click and calls a callback function, if provided.
  btnClickedHandler(event: MouseEvent) {
    console.log("btnClickedHandler"); // Log that the button was clicked.
    this.params.clicked(this.params.data); // Call the clicked callback function with the row data.
    console.log(this.params.data); // Log the data of the clicked row for reference.
  }
}
