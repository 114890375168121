import { Component } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';

/**
 * This class provides a login component for authenticating users.
 */
@Component({
  selector: 'spp-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  loginFormValues = {
    username: '',
    password: ''
  }

  /**
   * Constructor for the LoginComponent class.
   * @param authService - An instance of the AuthService class.
   * @param router - An instance of the Router class.
   */
  constructor(public authService: AuthService,
    private router: Router){}

    /**
   * Method to submit the login form.
   * Called from HTML Code.
   */
  async submitForm() {
    const result = await this.authService.login(this.loginFormValues.username, this.loginFormValues.password);
    
    if (result) {
      if (!this.authService.redirectUrl || this.authService.redirectUrl == '') {
        this.router.navigate(['/']);
      } else {
        this.router.navigate([this.authService.redirectUrl]);
      }
    }
  }

  /**
   * Method to navigate to the home page.
   * Called from HTML Code.
   */
  public navigateToHome() {
    this.router.navigate(['/']);
  }
}
