import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import Consignment from 'src/app/shared/dispatcher/consignments/consignment.model';
import { ConsignmentService } from 'src/app/shared/dispatcher/consignments/consignment.service';
import { WheelsetService } from 'src/app/shared/dispatcher/wheelsets/wheelset.service';
import { ErrorService } from 'src/app/shared/error/error.service';
import { IWheelsetData } from 'src/app/shared/model/wheelsetData.model';

/**
 * This class provides an overview component for displaying consignments and wheelsets.
 */
@Component({
  selector: 'spp-dispatcher-overview',
  templateUrl: './dispatcher-overview.component.html',
  styleUrls: ['./dispatcher-overview.component.scss']
})
export class DispatcherOverviewComponent implements OnInit {
  public textSearchValue = "";
  public consignments: Consignment[] = [];
  public consignements$?: Observable<Consignment[]>;
  public wheelsets$?: Observable<IWheelsetData[]>;
  public selectedWheelset: Consignment | undefined;
  public showModal = false;
  public showCreateButton = false;

  /**
   * Constructor for the DispatcherOverviewComponent class.
   * @param consignmentsService - An instance of the ConsignmentService class.
   * @param wheelsetService - An instance of the WheelsetService class.
   * @param errorService - An instance of the ErrorService class.
   */
  constructor(public consignmentsService: ConsignmentService,
    private wheelsetService: WheelsetService,
    private errorService: ErrorService) {
    this.showCreateConsignmentContractModal = this.showCreateConsignmentContractModal.bind(this);
  }

  /**
   * Method called when the component is initialized.
   */
  ngOnInit(): void {
    this.consignements$ = this.consignmentsService.consignments$;
    this.wheelsets$ = this.wheelsetService.wheelsets;
    this.loadGrids();
  }

  /**
   * Method to filter the grids.
   * Called from HTML Code.
   */
  public filterGrids() {
    if (this.textSearchValue === '') {
      this.loadGrids();
    } else {
      this.consignmentsService.getConsignments(this.textSearchValue);
      this.wheelsetService.getWheelsets(this.textSearchValue);
    }
  }

  /**
   * Method to reset the grids.
   * Called from HTML Code.
   */
  public resetGrids() {
    this.textSearchValue = "";
    this.loadGrids();
  }

  /**
   * Method to load the grids.
   */
  public async loadGrids() {
    this.loadKommissionierungen();
    this.loadWheelsets();
  }

  /**
   * Private method to load the consignments.
   */
  private loadKommissionierungen() {
    try {
      this.consignmentsService.getConsignments();
    }
    catch (error) {
      this.errorService.showError(error);
    }
  }

  /**
   * Private method to load the wheelsets.
   */
  private async loadWheelsets() {
    try {
      this.wheelsetService.getWheelsets();
    }
    catch (error) {
      this.errorService.showError(error);
    }
  }

  /**
   * Method to show the create consignment contract modal.
   * @param selectedRowData - The selected consignment or wheelset.
   * @param showCreateButton - A boolean indicating if the create button should be shown.
   */
  public async showCreateConsignmentContractModal(selectedRowData: Consignment, showCreateButton: boolean) {
    // If showCreateButton is undefined -> we're not in responsive design
    this.showCreateButton = typeof showCreateButton === 'undefined' ? true : showCreateButton;
    this.selectedWheelset = selectedRowData;
    this.toggleModal();
  }

  /**
   * Method to create a consignment contract.
   */
  public async createConsginmentContract() {
    if (!this.selectedWheelset) {
      this.errorService.showError('No wheelset selected');
    } else {
      try {
        this.consignmentsService.createConsignmentAsync(this.selectedWheelset);
        this.toggleModal();
      } catch (error) {
        this.errorService.showError(error);
      }
    }
  }

  /**
   * Method to toggle the visibility of the modal.
   */
  public toggleModal() {
    if (this.showModal) {
      this.selectedWheelset = undefined;
    }

    this.showModal = !this.showModal;
  }
}
