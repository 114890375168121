import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from './auth.service';

/**
 * This function provides an Angular route guard that checks if the user is authenticated.
 * @param route - The activated route.
 * @param state - The router state.
 * @returns A boolean indicating if the route can be activated or a UrlTree to redirect to.
 */
export const authGuard: CanActivateFn = (route, state) => {
  // TODO: Prüfen ob angemeldet
  const authService = inject(AuthService);
  const router = inject(Router);
  
  authService.redirectUrl = state.url;

  if (authService.isAuthenticated) {
    return true;
  }

  return router.parseUrl('/login');
};
