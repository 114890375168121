import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'spp-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  @Input() modalIsOpen = false;
  @Output() modalIsOpenChange = new EventEmitter<boolean>(); // Hinzufügen der Output-Eigenschaft
  @Input() header = "";
  @Input() modalDismissedEvent: any;

  closeModal() {
    this.modalIsOpen = false;
    this.modalIsOpenChange.emit(this.modalIsOpen); // Informieren der übergeordneten Komponente über die Änderung
  }

  public dismissModel() {
    this.modalDismissedEvent();
  }
}
