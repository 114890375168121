<div class="row" *ngIf="!authService.isAuthenticated">
    <div class="col-12 offset-md-4 col-md-4">
        <h2>LOGIN</h2>
    </div>
    <div class="col-12 offset-md-4 col-md-4">
        <div class="row">
            <form class="col-12" #loginForm="ngForm" (ngSubmit)="submitForm()">
                <div class="row">
                    <div class="col-12">
                        <ds-form-field>
                            <label ds-label for="username">Benutzername</label>
                            <input ds-input id="username" type="text" required [(ngModel)]="loginFormValues.username" name="username" />
                        </ds-form-field>
                    </div>
                    <div class="col-12">
                        <ds-form-field>
                            <label ds-label for="password">Passwort</label>
                            <input ds-input id="password" type="password" required [(ngModel)]="loginFormValues.password" name="password" />
                        </ds-form-field>
                    </div>
                </div>
                <div class="row mt-5x">
                    <div class="col-12 col-sm-6">
                        <button type="submit" ds-button class="w-100" [disabled]="!loginForm.valid">Anmelden</button>
                    </div>
                    <div class="col-12 col-sm-6">
                        <button type="button" ds-button class="w-100" variant="outline" (click)="navigateToHome()">Abbrechen</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>