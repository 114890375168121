import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IWheelsetData } from '../../model/wheelsetData.model';

@Injectable({
  providedIn: 'root'
})
export abstract class WheelsetService {
  /**
   * Observablee object wich subscriber can subscribe to in order to get notified about changes
   */
  private wheelsets$?: Observable<IWheelsetData[]>;

  /**
   * Internally used to send new data to the subscriber
   */
  _wheelsets: BehaviorSubject<IWheelsetData[]> = new BehaviorSubject<IWheelsetData[]>([]);

   /**
   * Getter for the wheelsets observable
   * @returns Observable of wheelsets
   */
  public get wheelsets(): Observable<IWheelsetData[]> {
    this.wheelsets$ ??= this._wheelsets.asObservable();
    return this._wheelsets;
  }

  /**
   * Fetch wheelsets and automatically notify subscribers
   * @param filter Optional filter for the wheelsets
   */
  public abstract getWheelsets(filter?: string): void;
}
