// Import necessary Angular and RxJS modules for service declaration.
import { Injectable } from '@angular/core';
// Import the interface that defines the structure of consignment data specific to workhouse operatives.
import { IConsignmentWHO } from './consignmentWHO.model';
import { Observable } from 'rxjs';

/**
 * Use the Injectable decorator to make this service available for dependency injection across the Angular application.
 * providedIn: 'root' ensures that the service is provided in the root injector, making it a singleton and accessible application-wide.
 */
@Injectable({
  providedIn: 'root'
})
/**
 * Define an abstract class named WorkhouseoperativeService. This class serves as a base for services related to managing
 * workhouse operative consignments. It declares abstract methods that must be implemented by subclasses, ensuring a consistent interface
 * for consignment operations across different parts of the application.
 */
export abstract class WorkhouseoperativeService {

  /**
   * Declares an abstract method to get consignments. This method must be implemented by subclasses to return an Observable
   * stream of consignments data, allowing components to reactively receive updates.
   * @returns Observable<IConsignmentWHO[]> - An Observable stream of an array of consignment WHO objects.
   */
  public abstract getConsignments(): Observable<IConsignmentWHO[]>;

  /**
   * Declares an abstract method to update consignments. This method must be implemented by subclasses to handle the updating
   * of a consignment item, ensuring the data integrity and reactivity of the consignment list.
   * @param changedItem An object of type IConsignmentWHO representing the consignment item to be updated.
   * @returns Promise<IConsignmentWHO> - A promise that resolves with the updated consignment item.
   */
  public abstract updateConsignments(changedItem: IConsignmentWHO): Promise<IConsignmentWHO>;
}
