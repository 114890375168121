// Import backend service classes for handling consignments, wheelsets, and workhouse operative tasks.
// These services connect to the real backend APIs to perform CRUD operations and more.
import ConsignmentBackendService from "src/app/shared/dispatcher/consignments/consignment.backend.service";
import WheelsetBackendService from "src/app/shared/dispatcher/wheelsets/wheelset.backend.service";
import { WorkhouseperativeBackendService } from "src/app/shared/workhouseoperative/workhouseoperative.backend.service";

// Define and export the configuration variables for the development (Dev) environment.
// This configuration specifies settings that are specific to development phase,
// including API URLs and service implementations that interact with the development backend.
export const environment = {
  // A string that names this specific environment as 'Dev', indicating it is meant for development purposes.
  environmentName: 'Dev',
  // The base URL for all backend API requests in the development environment.
  // This URL points to the development server where the backend APIs are hosted.
  apiUrl: 'https://spp-backend.c2-cloud.de/api/',
  // An object that maps service classes to their respective areas in the application.
  // This ensures the application uses the correct service implementations that communicate with the development backend.
  services: {
    workhouseoperativeService: WorkhouseperativeBackendService, // Service implementation for workhouse operative tasks
    consignmentService: ConsignmentBackendService, // Service implementation for managing consignments
    wheelsetService: WheelsetBackendService // Service implementation for managing wheelsets
  }
};
