import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError, lastValueFrom } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { WorkhouseoperativeService } from './workhouseoperative.service';
import { IConsignmentWHO } from './consignmentWHO.model';
import { IResponseBackendDTO } from '../model/responseBackendDTO.model';
import { IWheelsetData } from '../model/wheelsetData.model';
import { ErrorService } from '../error/error.service';

@Injectable({
  providedIn: 'root'
})
export class WorkhouseperativeBackendService extends WorkhouseoperativeService {
  // Subject to hold and manage the state of consignments data.
  private consignmentsSubject = new BehaviorSubject<IConsignmentWHO[]>([]);

  constructor(
    public httpClient: HttpClient, // Injectable HttpClient for making API requests.
    public errorService: ErrorService // Error handling service.
  ) {
    super();
    this.loadConsignmentData(); // Initial data load from the API.
  }

  /**
   * Loads initial consignment data from the backend and updates the BehaviorSubject.
   */
  private loadConsignmentData(): void {
    this.httpClient.get<IResponseBackendDTO<IWheelsetData[]>>(`${environment.apiUrl}orders`).pipe(
      map(response => {
        // Check for errors in the response and return the data or an empty array.
        return !response.hasError ? response.value : [];
      }),
      catchError(error => {
        // Handle any errors during the API call.
        console.error('Error loading initial data:', error);
        return throwError(() => new Error('Error loading initial data'));
      })
    ).subscribe(data => this.consignmentsSubject.next(data));
  }

  /**
   * Returns an Observable of the current consignment data.
   * @returns Observable stream of consignment data.
   */
  public getConsignments(): Observable<IConsignmentWHO[]> {
    return this.consignmentsSubject.asObservable();
  }

  /**
  * Updates a specific consignment item on the backend. After successfully updating,
  * it refreshes the consignment data from the backend to ensure that the local data
  * reflects the latest state, including changes made by other users.
  * This approach ensures the frontend is always in sync with the backend data.
  * @param changedItem The consignment item to update.
  * @returns A Promise resolving to the updated consignment item. The promise
  * reflects the outcome of the update operation on the backend.
  */
  public async updateConsignments(changedItem: IWheelsetData): Promise<IWheelsetData> {
    const updatedItem = await lastValueFrom(
      this.httpClient.put<IWheelsetData>(`${environment.apiUrl}orders/${changedItem.id}`, changedItem).pipe(
        catchError(this.handleError)
      )
    );

    // Reload consignment data from the backend to reflect any changes.
    this.loadConsignmentData();

    return updatedItem;
  }


  /**
   * Handles errors encountered during HTTP requests.
   * @param error The error object received.
   * @returns An Observable error with a formatted message.
   */
  private handleError(error: any) {
    // Format and throw the received error for further handling.
    return throwError(() => new Error(error.message || 'Server error'));
  }
}
