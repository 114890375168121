// Import Angular core decorators and utilities for component lifecycle hooks, form handling, and view encapsulation.
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
// Import services for responsive design and workhouse operative functionalities.
import { BreakpointService } from '../shared/breakpoint/breakpoint.service';
// Import AG Grid's GridApi for grid operations.
import { GridApi } from 'ag-grid-community';
import { WorkhouseoperativeService } from '../shared/workhouseoperative/workhouseoperative.service';
// Import the interface for consignments specific to workhouse operatives.
import { IConsignmentWHO } from '../shared/workhouseoperative/consignmentWHO.model';
// Import Angular's forms module for template-driven forms.
import { NgForm } from '@angular/forms';

// Use the Component decorator to define component metadata.
@Component({
  selector: 'spp-workhouseoperative', // The selector for this component when used in HTML.
  templateUrl: './workhouseoperative.component.html', // The path to the template file.
  styleUrls: ['./workhouseoperative.component.scss'] // The path to the styles file.
})
// Implement OnInit and OnDestroy to manage lifecycle hooks.
export class WorkhouseoperativeComponent implements OnInit, OnDestroy {
  public consignments?: Observable<IConsignmentWHO[]>; // An observable of consignment data for the component.
  public selectedWheelset: IConsignmentWHO = {} as IConsignmentWHO; // The currently selected wheelset/consignment.
  public showModal = false; // Controls the visibility of modal dialogs.
  public showAssignModal = false; // Controls the visibility of the assignment modal.
  public assign = true; // A flag to determine the state of assignment.
  private gridApi!: GridApi; // An instance of the GridApi for AG Grid operations.
  public selectOptionsStorage: { id: string, label: string }[] = []; // Options for dropdowns in the UI.
  public isMobile = false; // A flag to indicate if the application is being viewed on a mobile device.
  private subscription!: Subscription; // A subscription to manage reactive updates.
  @ViewChild('assignmentForm') assignmentForm?: NgForm; // A view child to access the form in the template.

  // Inject dependencies for services.
  constructor(private workhouseoperativeService: WorkhouseoperativeService, private breakpointService: BreakpointService) {
    // Bind the showCreateConsignmentWorkhouseModal method to ensure 'this' context.
    this.showCreateConsignmentWorkhouseModal = this.showCreateConsignmentWorkhouseModal.bind(this);
  }

  ngOnInit(): void {
    // Subscribe to the breakpoint service to adjust UI for mobile devices.
    this.subscription = this.breakpointService.isMobile$.subscribe(isMobile => {
      this.isMobile = isMobile;
    });
    // Load grid data and select options on initialization.
    this.loadGrids();
    this.generateSelectOptions();
  }

  ngOnDestroy() {
    // Clean up subscriptions to prevent memory leaks.
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  // Generate select options for storage locations from consignment data.
  generateSelectOptions(): void {
    this.workhouseoperativeService.getConsignments().subscribe(consignments => {
      // Create a set of unique shelf_rack values for dropdown options.
      const uniqueStoredValues = new Set(consignments.map(c => c.shelf_rack));
      this.selectOptionsStorage = Array.from(uniqueStoredValues).map(storedValue => ({
        id: storedValue,
        label: storedValue
      }));
    });
  }

  // Toggle modal visibility based on the selected wheelset's status and device type.
  public toggleModal() {
    if ((this.selectedWheelset?.status === "offen" && this.isMobile) || this.showAssignModal) {
      this.showAssignModal = !this.showAssignModal;
    } else {
      if (!this.showAssignModal)
        this.showModal = !this.showModal;
    }
  }

  // AG Grid's onGridReady event handler to fit columns to grid size.
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  // Handler for when the Grid API is ready, to adjust grid size.
  onGridApiReady(api: GridApi) {
    this.gridApi = api;
    this.gridApi.sizeColumnsToFit();
  }

  // Show modal for creating or updating consignments based on status and device type.
  public async showCreateConsignmentWorkhouseModal(selectedRowData: IConsignmentWHO) {
    this.selectedWheelset = selectedRowData;
    // Logic to determine which modal to show based on the status and device type.
    if (this.selectedWheelset?.status === "offen" && !this.isMobile) {
      this.touchlessLogisticAssignment(this.selectedWheelset);
    } else {
      this.toggleModal();
    }
    this.assign = true;
  }

  // Toggle the assignment state to edit mode.
  public editAssignment() {
    this.assign = false;
  }

  // Load logistic orders into the grid.
  private async loadLogisticOrders() {
    this.consignments = this.workhouseoperativeService.getConsignments()
  }

  // Wrapper function to load grids with logistic orders.
  public async loadGrids() {
    await this.loadLogisticOrders();
  }

  // Update the logistic assignment based on form changes and modal state.
  public async updateLogisticAssignment() {
    // Ensure the form has been touched and a wheelset is selected before updating.
    if (this.selectedWheelset && this.assignmentForm && this.assignmentForm.touched) {
      await this.workhouseoperativeService.updateConsignments(this.selectedWheelset);
    }
    // Logic for handling assignments when the assign modal is shown.
    if (this.showAssignModal) {
      this.selectedWheelset.status = "angenommen";
      await this.workhouseoperativeService.updateConsignments(this.selectedWheelset);
    }
    // Toggle modal visibility after update.
    this.toggleModal();
  }

  // Perform a touchless logistic assignment for a selected item.
  public async touchlessLogisticAssignment(touchlessItem: IConsignmentWHO) {
    this.selectedWheelset.status = "angenommen";
    await this.workhouseoperativeService.updateConsignments(touchlessItem);
  }

  // Reset and toggle modal visibility.
  resetModal() {
    this.toggleModal()
  }
}
