import { BehaviorSubject, Observable, catchError, map, throwError } from "rxjs";
import { HttpService } from "../../http/http.service";
import Consignment from "./consignment.model";
import { ConsignmentService } from "./consignment.service";
import { Injectable } from "@angular/core";
import { ErrorService } from "../../error/error.service";

/**
 * This class provides a service for managing consignments.
 */
@Injectable({
  providedIn: 'root'
})
export default class ConsignmentBackendService implements ConsignmentService {
  timeFormatter: any;

  /**
   * Constructor for the ConsignmentBackendService class.
   * @param httpService - An instance of the HttpService class.
   * @param errorService - An instance of the ErrorService class.
   */
  constructor(private httpService: HttpService,
    private errorService: ErrorService) {

  }

  private _consignments: BehaviorSubject<Consignment[]> = new BehaviorSubject<Consignment[]>([]);
  public consignments$: Observable<Consignment[]> = this._consignments.asObservable();

  /**
   * Method to get all consignments.
   * @returns An Observable that emits an array of consignments.
   */
  public getConsignments(textSearchValue: string): void {
    this.httpService.get<Consignment[]>('orders').pipe(
      map(response => {
        // Check for errors in the response and return the data or an empty array.
        let returnValues= [];
        if (textSearchValue) {
          returnValues = response.value.filter((consignment: Consignment) => {
              return consignment.hb_number.indexOf(textSearchValue) >= 0 ||
              consignment.license_plate.indexOf(textSearchValue) >= 0 ||
              consignment.wheelset_number === textSearchValue ||
              consignment.shelf_rack === textSearchValue ||
              consignment.vin === textSearchValue;
          });
        } else {
          returnValues = response.value;
        }
        return !response.hasError ? returnValues : [];
      }),
      catchError(error => {
        // Handle any errors during the API call.
        console.error('Error loading initial data:', error);
        return throwError(() => new Error('Error loading initial data'));
      })
    ).subscribe(data => this._consignments.next(data));
  }

  /**
   * Method to create a new consignment.
   * @param wheelset - The consignment to create.
   * @returns An Observable that emits the created consignment.
   */
  public createConsignmentAsync(wheelset: Consignment): void {
    this.httpService.post<Consignment>('orders', wheelset).pipe(
      map(response => {
        // Check for errors in the response and return the data or an empty array.
        return !response.hasError ? response.value : {};
      }),
      catchError(error => {
        // Handle any errors during the API call.
        console.error('Error loading initial data:', error);
        return throwError(() => new Error('Error loading initial data'));
      })
    ).subscribe(data => {
      const newConsignment: Consignment = data as Consignment;
      const currentOrders = this._consignments.getValue();
      currentOrders.push(newConsignment);
      this._consignments.next([...currentOrders]);
    });
  }
}
