<div class="row">
    <div class="col-12 mb-5x">
        <div class="row">
            <div class="col-10 col-sm-11 mb-2x col-md-6 mb-md-2x col-lg-10 mb-lg-0x">
                <input ds-input [(ngModel)]="textSearchValue" [placeholder]="'dispatcher-overview.search-placeholder' | translate" />
            </div>
            <div class="col-2 col-sm-12 mb-2x col-md-6 mb-md-2x col-lg-1 mb-lg-0x">
                <button ds-button [variant]="'outline'" (click)="resetGrids()" class="w-100 d-none d-sm-block">{{'dispatcher-overview.reset' | translate}}</button>
                <button ds-button [variant]="'outline'" (click)="resetGrids()" class="w-100 d-sm-none"><ds-icon icon="filter_delete"></ds-icon></button>
            </div>
            <div class="col-12 col-sm-12 mb-2x col-md-6 mb-md-2x col-lg-1 mb-lg-0x">
                <button ds-button (click)="filterGrids()" class="w-100">{{'dispatcher-overview.search' | translate}}</button>
            </div>
        </div>
    </div>
    <div class="col-lg-12 col-xl-6">
        <div class="row">
            <b class="col-12 d-none d-sm-block">{{'dispatcher-overview.consignments' | translate}}</b>
            <b class="col-12 d-sm-none">{{'dispatcher-overview.consignments' | translate}} ({{consignments.length}})</b>
        </div>
        <div class="row">
            <div class="d-none d-sm-block col-sm-12">
                <spp-wheelset-grid [stored]="false"
                [resizeOnLoad]="true"
                [consignments]="consignements$"
                [pagination]="true"
                [pageSize]="10"></spp-wheelset-grid>
            </div>
            <div class="d-sm-none">
                <spp-dispatcher-consignment-responsive
                [consignmentsOrWheelsets$]="consignements$"
                [showStatusBackgroundColor]="true"
                [actionCallback]="showCreateConsignmentContractModal"
                [pageSize]="5"></spp-dispatcher-consignment-responsive>
            </div>
        </div>
    </div>
    <div class="col-lg-12 col-xl-6 mt-5x mt-sm-0x">
        <div class="row">
            <b class="col-12 d-none d-sm-block">{{'dispatcher-overview.wheelsets' | translate}}</b>
            <!-- <b class="col-12 d-sm-none">{{'dispatcher-overview.wheelsets' | translate}} ({{wheelsets.length}})</b> -->
        </div>
        <div class="row">
            <div class="d-none d-sm-block col-12">
                <spp-wheelset-grid [stored]="true"
                [resizeOnLoad]="true"
                [gridFilterable]="true"
                [consignments]="wheelsets$"
                [showActionButton]="true"
                [actionButtonClickedCallback]="showCreateConsignmentContractModal"
                [pagination]="true"
                [pageSize]="10"></spp-wheelset-grid>
            </div>
            <div class="d-sm-none">
                <spp-dispatcher-consignment-responsive
                [consignmentsOrWheelsets$]="wheelsets$"
                [showActionButton]="true"
                [actionCallback]="showCreateConsignmentContractModal"></spp-dispatcher-consignment-responsive>
            </div>
        </div>
    </div>
    <spp-modal [(modalIsOpen)]="showModal"
    (modalClose)="toggleModal()"
    (modalDismiss)="toggleModal()"
    [header]="'dispatcher-overview.modal-title' | translate">
        <div class="container-fluid">
            <div class="row">
                <h2 class="col-12">
                    {{'dispatcher-overview.selected-wheelset' | translate}}
                </h2>
            </div>
            <div class="row mb-5x">
                <div class="col-12">
                    {{selectedWheelset?.wheelset_number}}
                </div>
            </div>
            <div class="row mb-5x">
                <div class="col-12">
                    <h3>{{'dispatcher-overview.modal-more-information' | translate}}:</h3>
                </div>
                <div class="col-12">
                    {{selectedWheelset?.license_plate}}
                </div>
                <div class="col-12">
                    {{selectedWheelset?.shelf_rack}}
                </div>
                <div class="col-12">
                    {{selectedWheelset?.vin}}
                </div>
            </div>
            <div class="row">
                <button class="col-sm-12 col-md-6" *ngIf="showCreateButton" ds-button (click)="createConsginmentContract()">{{'dispatcher-overview.create' | translate}}</button>
                <button class="col-sm-12 col-md-6" ds-button [variant]="'outline'" (click)="toggleModal()">{{'dispatcher-overview.cancel' | translate}}</button>
            </div>
        </div>
    </spp-modal>
</div>
