import { Component, Input, OnInit } from '@angular/core';
import { ColDef, ColGroupDef, GridApi, GridOptions } from 'ag-grid-community';
import Consignment from 'src/app/shared/dispatcher/consignments/consignment.model';
import { ResponsiveGridCellRendererComponent } from './responsive-grid-cell-renderer/responsive-grid-cell-renderer.component';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { IWheelsetData } from 'src/app/shared/model/wheelsetData.model';

/**
 * This class provides a responsive component for displaying consignments or wheelsets in an ag-grid.
 */
@Component({
  selector: 'spp-dispatcher-consignment-responsive',
  templateUrl: './dispatcher-consignment-responsive.component.html',
  styleUrls: ['./dispatcher-consignment-responsive.component.scss']
})
export class DispatcherConsignmentResponsiveComponent implements OnInit {
  @Input() consignmentsOrWheelsets$?: Observable<IWheelsetData[] | Consignment[]>;
  @Input() showStatusBackgroundColor = false;
  @Input() showActionButton = false;
  @Input() actionCallback?: any;
  @Input() pageSize = 10;

  private gridApi: GridApi | undefined = undefined;

  /**
   * Constructor for the DispatcherConsignmentResponsiveComponent class.
   * @param translateService - An instance of the TranslateService class.
   */
  constructor(private translateService: TranslateService) {
    this.translateService.onLangChange.subscribe(() => {
      this.gridApi?.refreshHeader();
    });
  }

  /**
   * Method called when the ag-grid is ready.
   * @param parameters - The grid parameters.
   */
  public onGridReady(parameters: any) {
    this.gridApi = parameters.api;
  }

  public columns: (ColDef<Consignment | IWheelsetData, any> | ColGroupDef<Consignment | IWheelsetData>)[] = [];

  public gridOptions: GridOptions<Consignment | IWheelsetData> = {
    defaultColDef: {
      resizable: true,
      sortable: true,
    },
    columnDefs: this.columns,
    getRowStyle: params => {
      const style = {
        backgroundColor: 'inherit'
      };
      switch (params.data?.status) {
        case 'offen':
          style.backgroundColor = '#F8CECC';
          break;
        case 'angenommen':
          style.backgroundColor = '#DAE8FC';
          break;
        case 'beendet':
          style.backgroundColor = '#D5E8D4';
          break;
      }

      return style;
    },
    pagination: true
  }

  public showModal = false;
  public selectedConsignmentOrWheelset?: Consignment;

  /**
   * Method called when the component is initialized.
   */
  ngOnInit(): void {
    this.gridOptions.paginationPageSize = this.pageSize;
    this.columns.push(
      {
        field: 'Aktion',
        headerName: 'Aktion',
        filter: true,
        cellRenderer: ResponsiveGridCellRendererComponent,
        cellRendererParams: {
          clicked: this.actionCallback,
          showEditButton: this.showActionButton
        },
        headerValueGetter: this.localizeHeader.bind(this)
      }
    );

  }

  /**
   * Method to localize the header of a column.
   * @param params - The column parameters.
   * @returns The localized header value.
   */
  public localizeHeader(params: any) {
    const headerIdentifier = 'wheelset-grid.' + params.colDef?.field;
    const translation = this.translateService.instant(headerIdentifier);
    const returnValue = translation == headerIdentifier ? params.colDef?.headerName : translation;

    return returnValue;
  }
}
