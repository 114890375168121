import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IResponseBackendDTO } from '../model/responseBackendDTO.model';

/**
 * This class provides methods for making HTTP requests.
 */
@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private host = environment.apiUrl;

  private defaultHttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  /**
   * Constructor for the HttpService class.
   * @param httpClient - An instance of the HttpClient class.
   */
  constructor(private httpClient: HttpClient) { }

  /**
   * Method to make a GET request.
   * @param endpoint - The endpoint to make the request to.
   * @returns An Observable of the response.
   */
  public get<T>(endpoint: string): Observable<IResponseBackendDTO<T>> {
    console.log("Sending GET request for " + endpoint);
    return this.httpClient.get<IResponseBackendDTO<T>>(this.getCompleteUrl(endpoint));
  }

   /**
   * Method to make a POST request.
   * @param endpoint - The endpoint to make the request to.
   * @param body - The body of the request.
   * @param headers - Optional headers to include in the request.
   * @returns An Observable of the response.
   */
  public post<T>(endpoint: string, body: T, headers?: HttpHeaders): Observable<IResponseBackendDTO<T>> {
    if (headers) {
      this.defaultHttpOptions.headers = headers;
    }
    return this.httpClient.post<IResponseBackendDTO<T>>(this.getCompleteUrl(endpoint), body, this.defaultHttpOptions);
  }

  /**
   * Method to make a PUT request.
   * @param endpoint - The endpoint to make the request to.
   * @param body - The body of the request.
   * @param headers - Optional headers to include in the request.
   * @returns An Observable of the response.
   */
  public put<T>(endpoint: string, body: T, headers?: HttpHeaders): Observable<IResponseBackendDTO<T>> {
    if (headers) {
      this.defaultHttpOptions.headers = headers;
    }
    return this.httpClient.put<IResponseBackendDTO<T>>(this.getCompleteUrl(endpoint), body, this.defaultHttpOptions);
  }

  /**
   * Method to make a DELETE request.
   * @param endpoint - The endpoint to make the request to.
   * @param headers - Optional headers to include in the request.
   * @returns An Observable of the response.
   */
  public delete<T>(endpoint: string, headers?: HttpHeaders): Observable<IResponseBackendDTO<T>> {
    if (headers) {
      this.defaultHttpOptions.headers = headers;
    }
    return this.httpClient.delete<IResponseBackendDTO<T>>(this.getCompleteUrl(endpoint), this.defaultHttpOptions);
  }

  /**
   * Private method to get the complete URL for a request.
   * @param endpoint - The endpoint to make the request to.
   * @returns The complete URL for the request.
   */
  private getCompleteUrl(endpoint: string): string {
    return this.host + endpoint;
  }
}
