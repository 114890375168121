<div class="row">
  <div class="col-lg-12">
    <div class="row">
          <b class="col-12">{{'workhouse-operative.show-warehouse' | translate}}</b>

    </div>
    <div class="row">
      <div class="col-12">
        <spp-wheelset-grid *ngIf="!isMobile && consignments"
        (gridReadyEvent)="onGridApiReady($event)"
          [stored]="false"
          [consignments]="consignments"
          [showActionButton]="true"
          [resizeOnLoad]="true"
          [pagination]="true"
          [logistic]="true"
          [pageSize]="20"
          [actionButtonClickedCallback]="showCreateConsignmentWorkhouseModal">
        </spp-wheelset-grid>
      </div>
      <div class="col-12">
        <spp-workhouseoperative-responsive
        *ngIf="isMobile"
        [showActionButton]="true"
        [actionCallback]="showCreateConsignmentWorkhouseModal"></spp-workhouseoperative-responsive>
    </div>
    </div>
    <spp-modal [(modalIsOpen)]="showModal"
    (modalClose)="toggleModal()"
    [header]="'Kommissionsauftrag bearbeiten'">
    <div class="container-fluid">
      <form #assignmentForm="ngForm" (ngSubmit)="updateLogisticAssignment()">
        <div *ngIf="assign" class="d-flex justify-content-end">
          <button ds-button (click)="editAssignment()">Entsperren</button>
        </div>
        <div class="row">
          <h2 class="col-12">Zugewiesener Kommissionierer:</h2>
        </div>
        <div class="row mb-5x">
          <div class="col-12">
            <ds-form-field>
              <ds-select [options]="[
                  {id:'Bearbeiter 1', label:'Bearbeiter 1'},
                  {id:'Bearbeiter 2', label:'Bearbeiter 2'},
                  {id:'Bearbeiter 3', label:'Bearbeiter 3'}]"
                [(ngModel)]="selectedWheelset.worker"
                name="workerSelect"
                >
              </ds-select>
            </ds-form-field>
          </div>
        </div>
        <div class="row">
          <h2 class="col-12">Status:</h2>
        </div>
        <div class="row mb-5x">
          <div class="col-12">
            <ds-form-field>
              <ds-select [options]="[
                  {id:'offen', label:'offen'},
                  {id:'angenommen', label:'angenommen'},
                  {id:'beendet', label:'beendet'}]"
                [(ngModel)]="selectedWheelset.status"
                [disabled]="this.assign"
                name="statusSelect"
                >
              </ds-select>
            </ds-form-field>
          </div>
        </div>
        <div class="row">
          <h2 class="col-12">Lagerort:</h2>
        </div>
        <div class="row mb-5x">
          <div class="col-12">
            <ds-form-field>
              <ds-select [options]="selectOptionsStorage"
                [(ngModel)]="selectedWheelset.shelf_rack"
                [disabled]="this.assign"
                name="shelfRackSelect"
                >
              </ds-select>
            </ds-form-field>
          </div>
        </div>
        <div class="row">
          <button class="col-sm-12 col-md-6" ds-button type="submit">Ändern</button>
          <button class="col-sm-12 col-md-6" ds-button [variant]="'outline'" type="button" (click)="resetModal()">Abbrechen</button>
        </div>
      </form>
    </div>
  </spp-modal>
    <spp-modal [(modalIsOpen)]="showAssignModal"
      (modalClose)="toggleModal()"
      [header]="'Kommissionsauftrag annehmen'"
      >
      <div class="container-fluid">
        <div class="row">
          <h2 class="col-12">Auftrag annehmen?</h2>
        </div>
        <div class="row">
          <button class="col-sm-12 col-md-6" ds-button (click)="updateLogisticAssignment()" [disabled]="!assignmentForm.untouched || false">Bestätigen</button>
          <button class="col-sm-12 col-md-6" ds-button [variant]="'outline'" (click)="resetModal()">Abbrechen</button>
        </div>
      </div>
    </spp-modal>
  </div>
</div>
