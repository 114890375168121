
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColDef, ColGroupDef, GridApi, GridOptions, GridReadyEvent, SizeColumnsToContentStrategy } from 'ag-grid-community';
import Consignment from 'src/app/shared/dispatcher/consignments/consignment.model';
import { ButtonCellRendererComponent } from './button-cell-renderer/button-cell-renderer.component';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ConsignmentService } from '../dispatcher/consignments/consignment.service';
import { IConsignmentWHO } from '../workhouseoperative/consignmentWHO.model';
import { IWheelsetData } from '../model/wheelsetData.model';


@Component({
  selector: 'spp-wheelset-grid', // Component's custom HTML tag
  templateUrl: './wheelset-grid.component.html', // HTML template file path
  styleUrls: ['./wheelset-grid.component.scss'], // CSS styles file path
})
export class WheelsetGridComponent implements OnInit {
  // Component's input and output properties
  @Output() gridReadyEvent = new EventEmitter<GridApi>(); // Emits event when grid is ready
  @Input() stored = false; // Determines if only stored wheelsets should be displayed
  @Input() resizeOnLoad = false; // If true, columns resize to fit contents on load
  @Input() gridFilterable = false; // Enables filtering within the grid
  @Input() showActionButton = false; // Shows or hides the action button column
  @Input() consignments?: Observable<Consignment[] | IConsignmentWHO[]>; // Observable of consignments data
  @Input() actionButtonClickedCallback: any; // Callback function for action button click
  @Input() pagination = false; // Enables or disables pagination
  @Input() pageSize = 0; // Number of rows per page in the grid
  @Input() logistic = false; // Determines if logistic-specific columns should be shown
  @Input() actionButtonTitle = ''; // Title for the action button
  @Input() aktionButton = false; // Alternate input, possibly a typo or legacy code

  private gridApi!: GridApi<Consignment>; // Grid API instance
  public testObservable: Observable<Consignment[]> = new Observable(); // Example observable for demonstration

  // Component constructor injecting necessary services
  constructor(private translateService: TranslateService, private consignementService: ConsignmentService) {
    // Subscribe to language change events to refresh grid headers
    this.translateService.onLangChange.subscribe(() => {
      this.gridApi?.refreshHeader();
    });
  }

  // Strategy for auto-sizing columns
  public autoSizeStrategy: SizeColumnsToContentStrategy = {
    type: 'fitCellContents',
  };

  // Column definitions for the grid
  public columns: (ColDef<Consignment | IConsignmentWHO, any> | ColGroupDef<Consignment | IConsignmentWHO>)[] = [
    // Basic column configurations with dynamic header localization
    {
      field: 'wheelset_number',
      headerName: 'Radsatznummer',
      headerValueGetter: this.localizeHeader.bind(this)
    },
    // Additional columns follow similar pattern
    // Value formatter for 'date' column to format dates according to 'de-DE' locale
    {
      field: 'date',
      headerName: 'Datum',
      headerValueGetter: this.localizeHeader.bind(this),
      valueFormatter: (params) => {
        if (params.value) {
          const date = new Date(params.value);
          return date.toLocaleDateString('de-DE');
        }
        return '';
      }
    }
  ];

  // Grid options configuration
  public gridOptions: GridOptions<Consignment | IConsignmentWHO> = {
    defaultColDef: {
      resizable: true,
      sortable: true
    },
    columnDefs: this.columns
  }

  // Event handler for grid readiness
  onGridReady(params: GridReadyEvent) {
    this.gridReadyEvent.emit(params.api);
  }

  // OnInit lifecycle hook to configure grid options based on inputs
  ngOnInit(): void {
    // Setting pagination options based on component inputs
    this.gridOptions.pagination = this.pagination,
      this.gridOptions.paginationPageSize = this.pageSize;

    // Initializing grid data based on 'stored' input
    if (this.stored) {
      this.initializeStoredWheelsetsGrid();
    } else {
      this.initializeConsignmentsGrid();
    }

    // Conditionally adding action button column
    if (this.showActionButton && this.logistic) {
      this.columns.push({
        field: 'Aktion',
        cellRenderer: ButtonCellRendererComponent, // Using a custom cell renderer component for the action button
        cellRendererParams: {
          clicked: this.actionButtonClickedCallback, // Callback when button is clicked
          buttonText: getActionButtonTitle, // Dynamic button text
          isDynamic: this.logistic ? true : false // Dynamism based on 'logistic' input
        },
      });
    }

    // Helper function to determine button text, needs implementation details
    function getActionButtonTitle(params: any) {
      return params
    }

    // Enabling column filtering based on 'gridFilterable' input
    if (this.gridFilterable) {
      if (this.gridOptions.defaultColDef) {
        this.gridOptions.defaultColDef.filter = true;
      }
    }
  }

  // Custom comparator function for sorting based on predefined statuses
  private customComparator(a: string, b: string): number {
    const sortOrder: { [key: string]: number } = {
      'offen': 1,
      'angenommen': 2,
      'beendet': 3
    };

    const orderA = sortOrder[a];
    const orderB = sortOrder[b];

    if (orderA && orderB) {
      return orderA - orderB;
    } else if (orderA) {
      return -1;
    } else if (orderB) {
      return 1;
    }
    return a.localeCompare(b);
  }

  // Initializes grid for consignments, adding columns and configurations specific to non-stored wheelsets
  private initializeConsignmentsGrid() {
    // Adding 'status' and 'hb_number' columns with localized headers and custom sorting
    this.columns.push({
      field: 'status',
      headerName: 'Status',
      headerValueGetter: this.localizeHeader.bind(this),
      sort: 'asc',
      comparator: this.customComparator
    },
      {
        field: 'hb_number',
        headerName: 'HB Nummer',
        headerValueGetter: this.localizeHeader.bind(this)
      });

    // Additional logistic-specific columns
    if (this.logistic) {
      this.columns.push({
        field: 'shelf_rack',
        headerName: 'Einlagerungsort',
        headerValueGetter: this.localizeHeader.bind(this)
      },
        {
          field: 'worker',
          headerName: 'Mitarbeiter',
        });
    }

    // Configuring row styles based on 'status' field values
    this.gridOptions.getRowStyle = (params => {
      let style: { background: string } = {
        background: 'inherit'
      };

      if (params) {
        switch (params.data?.status) {
          case 'offen':
            style = {
              background: '#F8CECC'
            };
            break;
          case 'angenommen':
            style = {
              background: '#DAE8FC'
            };
            break;
          case 'beendet':
            style = {
              background: '#D5E8D4'
            };
            break;
        }
      }
      return style;
    });
  }

  // Initializes grid for stored wheelsets, adding specific configurations
  private initializeStoredWheelsetsGrid() {
    // Adding 'stored' column with filtering enabled and a localized header
    this.columns.push({
      field: 'stored',
      filter: true,
      headerName: 'Eingelagert',
      headerValueGetter: this.localizeHeader.bind(this)
    });

    // Conditionally adding action button for stored wheelsets
    if (this.showActionButton) {
      this.columns.push({
        field: 'Aktion',
        cellRenderer: ButtonCellRendererComponent, // Custom cell renderer for action button
        cellRendererParams: {
          clicked: this.actionButtonClickedCallback,
          buttonText: 'button-cell-renderer.button-title' // Button text localization key
        },
      });
    }
  }

  // Method to localize grid headers using ngx-translate
  public localizeHeader(params: any) {
    const headerIdentifier = 'wheelset-grid.' + params.colDef?.field; // Constructing localization key
    const translation = this.translateService.instant(headerIdentifier); // Getting translation
    const returnValue = translation == headerIdentifier ? params.colDef?.headerName : translation; // Defaulting to predefined header name if translation not found

    return returnValue; // Returning localized header name
  }
}
