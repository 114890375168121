// Import Angular core decorators for component lifecycle hooks, and input properties.
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
// Import AG Grid interfaces for column definitions, grid options, and row node interactions.
import { ColDef, ColGroupDef, GridOptions, IRowNode } from 'ag-grid-community';
// Import the custom cell renderer component for responsive button actions.
import { ResponsiveButtonCellRendererComponent } from '../../shared/wheelset-grid/responsive-button-cell-renderer/responsive-button-cell-renderer.component';
// Import the consignment model for type definitions.
import Consignment from 'src/app/shared/dispatcher/consignments/consignment.model';
// Import RxJS utilities for handling observables and subscriptions.
import { Observable, Subscription } from 'rxjs';
// Import the interface and service for workhouse operative consignments.
import { IConsignmentWHO } from 'src/app/shared/workhouseoperative/consignmentWHO.model';
import { WorkhouseoperativeService } from 'src/app/shared/workhouseoperative/workhouseoperative.service';

// Use the Component decorator to define metadata for the WorkhouseoperativeResponsiveComponent.
@Component({
  selector: 'spp-workhouseoperative-responsive', // The custom HTML tag for this component.
  templateUrl: './workhouseoperative-responsive.component.html', // The location of the template file.
  styleUrls: ['./workhouseoperative-responsive.component.scss'] // The location of the style file.
})
// Implement OnInit and OnDestroy lifecycle hooks for initialization and cleanup tasks.
export class WorkhouseoperativeResponsiveComponent implements OnInit, OnDestroy {
  @Input() showStatusBackgroundColor = false; // Allow external control over status background color visibility.
  @Input() showActionButton = false; // Allow external control over the visibility of the action button.
  @Input() actionCallback?: any; // An optional callback function for the action button.
  @Input() pageSize = 20; // Default page size for grid pagination.

  public consignments?: Observable<IConsignmentWHO[]>; // Observable stream of consignment data.

  private subscription: Subscription = new Subscription(); // Subscription holder for cleanup.

  // Define the column definitions for AG Grid.
  public columns: (ColDef<IConsignmentWHO, any> | ColGroupDef<IConsignmentWHO>)[] = [];

  // Configure default grid options.
  public gridOptions: GridOptions<IConsignmentWHO> = {
    defaultColDef: {
      resizable: true, // Allow column resizing.
      sortable: true, // Allow column sorting.
    },
    columnDefs: this.columns, // Set column definitions from the columns array.
    getRowStyle: params => { // Function to dynamically set row styles based on consignment status.
      const style = {
        backgroundColor: 'inherit' // Default background color.
      };
      switch (params.data?.status) { // Change background color based on the consignment status.
        case 'offen':
          style.backgroundColor = '#F8CECC'; // Light red for open consignments.
          break;
        case 'angenommen':
          style.backgroundColor = '#DAE8FC'; // Light blue for accepted consignments.
          break;
        case 'beendet':
          style.backgroundColor = '#D5E8D4'; // Light green for completed consignments.
          break;
      }
      return style;
    },
    pagination: true // Enable pagination for the grid.
  }

  public showModal = false; // Control visibility of the modal dialog.

  public selectedConsignmentOrWheelset?: Consignment; // Hold the currently selected consignment or wheelset.

  // Inject the WorkhouseoperativeService to fetch and update consignments.
  constructor(private workhouseoperativeService: WorkhouseoperativeService) { }

  ngOnInit(): void {
    // Fetch consignments data on component initialization.
    this.consignments = this.workhouseoperativeService.getConsignments();
    // Set the pagination page size.
    this.gridOptions.paginationPageSize = this.pageSize;
    // Configure columns for the grid.
    this.columns.push(
      {
        field: 'Aktion', // Define a column for actions.
        filter: false, // Disable filtering for this column.
        sortable: false, // Disable sorting for this column.
        cellRenderer: ResponsiveButtonCellRendererComponent, // Use the custom cell renderer component.
        cellRendererParams: {
          clicked: this.actionCallback, // Pass the action callback to the cell renderer.
          showEditButton: this.showActionButton // Control visibility of the edit button.
        },
      },
      {
        field: 'status', // Define a column for the consignment status.
        headerName: 'Status', // Set the header name.
        sort: 'asc', // Default sorting order.
        hide: true, // Initially hide this column.
        comparator: this.customComparator // Use a custom comparator function for sorting.
      },
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe(); // Unsubscribe from all observables on component destruction to prevent memory leaks.
  }

  // Define a custom comparator for sorting consignments by status.
  private customComparator(a: string, b: string): number {
    const sortOrder: { [key: string]: number } = { // Predefined order for consignment statuses.
      'offen': 1,
      'angenommen': 2,
      'beendet': 3
    };
    const orderA = sortOrder[a]; // Get the order value for the first status.
    const orderB = sortOrder[b]; // Get the order value for the second status.
    // Compare and return the difference for sorting.
    if (orderA && orderB) {
      return orderA - orderB;
    } else if (orderA) {
      return -1; // Prioritize statuses defined in sortOrder.
    } else if (orderB) {
      return 1; // Deprioritize unknown or undefined statuses.
    }
    // Fallback to default string comparison if both statuses are unknown.
    return a.localeCompare(b);
  }
}
