import { Injectable } from '@angular/core';
import Consignment from './consignment.model';
import { Observable } from 'rxjs';

/**
 * This abstract class provides an interface for managing consignments.
 */
@Injectable({
  providedIn: 'root'
})
export abstract class ConsignmentService {
  public abstract readonly consignments$: Observable<Consignment[]>;

 /**
   * Method to get an Observable for consignments.
   * @param filter - An optional filter string to filter the consignments.
   */
  public abstract getConsignments(filter?: string): void;

  /**
     * Method to create a consignment based on a selected wheelset.
     * @param wheelset - The selected wheelset for which the consignment is to be created.
     */
  public abstract createConsignmentAsync(wheelset: Consignment): void;

  // public abstract sendTestRequest(): Observable<unknown>;
}
