<ag-grid-angular
    (gridReady)="onGridReady($event)"
    class="ag-theme-density"
    style="width: 100%"
    [rowData]="consignments | async"

    [columnDefs]="columns"
    [gridOptions]="gridOptions"
    domLayout="autoHeight"
    (gridReady)="onGridReady($event)"
[autoSizeStrategy]="autoSizeStrategy"
    >
  </ag-grid-angular>
