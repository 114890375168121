import { BehaviorSubject, Observable, catchError, map, throwError } from "rxjs";
import { HttpService } from "../../http/http.service";
import { Injectable } from "@angular/core";
import { ErrorService } from "../../error/error.service";
import { WheelsetService } from "./wheelset.service";
import { IResponseBackendDTO } from "../../model/responseBackendDTO.model";
import { IWheelsetData } from "../../model/wheelsetData.model";
/**
 * This class provides a backend service for managing wheelsets.
 */
@Injectable({
    providedIn: 'root'
})
export default class WheelsetBackendService extends WheelsetService {

    /**
     * Constructor for the WheelsetBackendService class.
     * @param httpService - An instance of the HttpService class.
     * @param errorService - An instance of the ErrorService class.
     */
    constructor(private httpService: HttpService,
        private errorService: ErrorService){
            super()
    }

    override _wheelsets: BehaviorSubject<IWheelsetData[]> = new BehaviorSubject<IWheelsetData[]>([]);
        
    /**
     * Method to get all wheelsets.
     * @param filter - An optional filter string to filter the wheelsets.
     */
    public getWheelsets(filter?: string | undefined): void {
        this.httpService.get<IWheelsetData[]>('wheelsets').pipe(
            map(response => {
              // Check for errors in the response and return the data or an empty array.
              let returnValues= [];
              if (filter) {
                returnValues = response.value.filter((wheelset: IWheelsetData) => {
                    return wheelset.hb_number.indexOf(filter) >= 0 ||
                    wheelset.license_plate.indexOf(filter) >= 0 ||
                    wheelset.wheelset_number === filter ||
                    wheelset.shelf_rack === filter ||
                    wheelset.vin === filter;
                });
              } else {
                returnValues = response.value;
              }
              return !response.hasError ? returnValues : [];
            }),
            catchError(error => {
              // Handle any errors during the API call.
              console.error('Error loading initial data:', error);
              return throwError(() => new Error('Error loading initial data'));
            })
          ).subscribe(data => this._wheelsets.next(data));
    }
}