import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";
import AuthenticatedUser from "./auth.user.model";
import * as jose from "jose";

/**
 * This class provides an HTTP interceptor for adding authentication information to HTTP requests.
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private currentUser: AuthenticatedUser = new AuthenticatedUser();
    private SECRET_KEY = 'S3CR3t_K3Y';

    /**
     * Constructor for the AuthInterceptor class.
     * @param authService - An instance of the AuthService class.
     */
    constructor(private authService: AuthService) {
        this.authService.userLoggedIn.subscribe((authenticatedUser: AuthenticatedUser) => {
            this.currentUser = authenticatedUser;
        });
    }

    /**
     * Method to intercept HTTP requests and add authentication information.
     * @param req - The outgoing HTTP request.
     * @param next - The next interceptor in the chain.
     * @returns An Observable that emits the final HTTP response.
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        console.log('Starting intercept');
        // let newReq = req.clone();
        // if (req.url.indexOf('./assets/i18n') == -1) {
        //     const token = await this.generateJWT();
        //     const newHeaders: HttpHeaders = req.headers.set('Authorization', `Bearer ${token}`);
        //     newReq = req.clone({
        //         headers: newHeaders
        //     });
        // }
        
        console.log('Ending intercept');
        return next.handle(req);
    }

    /**
     * Private method to generate a JSON Web Token (JWT).
     * @returns A Promise that resolves to the generated JWT.
     */
    private async generateJWT() {
        const token = await new jose.SignJWT()
        .setProtectedHeader({alg:'HS256'})
        .sign(new TextEncoder().encode(this.SECRET_KEY));
        return token;
    }
}