import { DsHeaderTagConfiguration } from '@bmw-ds/components';
import { DsNavigationItem } from '@bmw-ds/components/ds-interfaces/navigation-bar.interface';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './auth/auth.service';
import { Router } from '@angular/router';
import AuthenticatedUser from './auth/auth.user.model';

@Component({
  selector: 'spp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  // Locale configuration
  localeID: string | null = 'de';
  localeOptions = [
    {id: 'en', label: 'en', language: 'English'},
    {id: 'de', label: 'de', language: 'Deutsch'},
  ];
  constructor(private translate: TranslateService,
    public authService: AuthService,
    private router: Router){
    translate.setDefaultLang('de');
    translate.use('de');

    // Check for user login
    this.authService.userLoggedIn.subscribe((connectedUserData: AuthenticatedUser) => {
      this.userInfo = {
          username: `${connectedUserData.Nachname}, ${connectedUserData.Vorname}`,
          department: 'Dept 1',
          profileImg: 'https://www.gravatar.com/avatar/?d=identicon'
        };
    });
  }

  public changeLocale(localeID: string | null) {
    if (localeID) {
      this.translate.use(localeID);
    }
  }

  //Tag with environment for header
  environmentTagConfig: Partial<DsHeaderTagConfiguration> = { label: 'DEV' };
  userInfo: any | undefined = undefined;

  //Navigation configuration
  navigationItems: DsNavigationItem[] = [
    {
      label: 'Home',
      routerLink: '/',
      icon: 'home'
    },
    {
      label: 'Disponentenansicht',
      routerLink: '/disponent',
      icon: 'calendar_car'
    },
    {
      label: 'Lageristenansicht',
      routerLink: '/lagerist',
      icon: 'tire'
    },
    {
      label: 'Density Documentation',
      routerLink: 'https://density.bmwgroup.net',
      target: '_blank',
      icon: 'book',
    },
    {
      label: 'Developer Resources',
      icon: 'code_box',
      children: [
        {
          label: 'Component Library',
          routerLink: 'https://density.bmwgroup.net/components',
          target: '_blank',
        },
        {
          label: 'Density Icons',
          routerLink: 'https://density.bmwgroup.net/foundations/icons/density-icons',
          target: '_blank',
        },
      ],
    },
    {
      label: 'Design Resources',
      routerLink: 'https://density.bmwgroup.net/resources-downloads',
      target: '_blank',
      icon: 'figma',
    },
  ];

  title = 'SPP';

  public async logout() {
    await this.authService.logout();
    this.userInfo = undefined;
    this.router.navigate(['/']);
  }
}
