
import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import Consignment from '../../dispatcher/consignments/consignment.model';
import { ICellRendererAngularComp } from 'ag-grid-angular';


@Component({
  selector: 'spp-responsive-button-cell-renderer', // Custom HTML tag to use this component.
  templateUrl: './responsive-button-cell-renderer.component.html', // Path to the HTML template.
  styleUrls: ['./responsive-button-cell-renderer.component.scss'] // Path to the styles.
})
// The class implements the ICellRendererAngularComp interface, enabling integration with AG Grid as a cell renderer.
export class ResponsiveButtonCellRendererComponent implements ICellRendererAngularComp {
  // Property to hold the parameters passed to this cell renderer.
  public params: any;
  // Optional property to store a Consignment or Wheelset object if needed.
  public consignmentOrWheelset?: Consignment;

  // AG Grid's initialization method for cell renderers. It is called once when the cell is created.
  agInit(params: ICellRendererParams<any, any, any>): void {
    // Storing the parameters passed to the cell renderer for later use.
    this.params = params;
    // Storing the row data, which could be a Consignment or Wheelset, for easy access.
    this.consignmentOrWheelset = this.params.data;
  }

  // Method called by AG Grid to refresh the cell's content. Should return true if the refresh was successful.
  refresh(params: ICellRendererParams<any, any, any>): boolean {
    // Updating the parameters with the new values.
    this.params = params;
    // Returning true to indicate that the refresh was handled.
    return true;
  }

  // Custom method to handle button clicks within the cell.
  public handleBtnClick() {
    // Example: Logging the button click action and the current parameters to the console.
    console.log("handleBtnClick");
    console.log(this.params);
    // Calling a method provided in the parameters when the button is clicked.
    // This could be a callback function to handle the click event outside the cell renderer.
    this.params.clicked(this.params.data, this.params.showEditButton);
  }
}
